import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${grayscale[500]};

  h1, h2, h3, h4, h5 {
    font-family: "Sora", Helvetica, sans-serif;
  }
`
